import React from 'react'
import Hero from 'components/Hero'
import Intro from 'components/Intro'
import Navbar from 'components/Navbar'
import DownloadApp from 'components/DownloadApp'
import AboutUs from 'components/AboutUs'
import ContactUs from 'components/ContactUs'
import Footer from 'components/Footer'

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Intro />
      <DownloadApp />
      <AboutUs />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default LandingPage
