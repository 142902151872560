import {useState} from 'react'
import {useForm} from 'react-hook-form'
import React from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {},
  })

  const sendEmail = (data) => {
    setIsLoading(true)
    const endpoint = 'https://ase-tefter-prod.azurewebsites.net/feedback'
    const feedbackBody = {
      fullname: data?.firstName,
      email: data?.email,
      feedback: data?.message,
      category: 'Web',
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackBody),
    }

    fetch(endpoint, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        reset()
        toast.success('Poruka Uspješno Poslana', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      })
      .catch((error) => {
        console.error('Error sending data:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div
      id="contact"
      className="flex justify-center font-helvetica bg-dark-green w-screen py-12 lg:py-[132px] px-6 lg:px-[90px]"
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="flex justify-center items-center flex-col max-w-[1440px] py-6 px-4 lg:px-[112px] lg:py-16 bg-pale-green rounded-2xl lg:rounded-3xl">
        <p className="text-3xl lg:text-6xl font-black text-dark-green">
          Kontaktirajte nas<span className="text-light-green">.</span>
        </p>
        <div className="flex justify-end mt-3 lg:w-[564px] lg:-ml-24">
          <p className="bg-light-green w-[160px] h-2"></p>
        </div>
        <p className="text-left text-base lg:text-2xl mt-12 mb-4">
          Imate pitanja, prijedloge ili želite podijeliti svoje mišljenje s
          nama? Javite nam se putem forme za kontakt, i rado ćemo vam
          odgovoriti.
        </p>
        <p className="text-left text-base lg:text-2xl mb-12">
          Možete nam pisati i na našu email adresu{' '}
          <a href="mailto:podrška@tefter.ba?subject=Upit" className="font-bold">
            podrska@tefter.ba
          </a>{' '}
          sa svojim pitanjima, prijedlozima ili bilo kojim drugim povratnim
          informacijama.
        </p>
        <form
          onSubmit={handleSubmit(sendEmail)}
          className="grid grid-flow-row font-helvetica text-black-bold w-full xl:max-w-[1100px]"
        >
          <div className="flex flex-col lg:flex-row flex-1 gap-x-8 text-base lg:text-xl">
            <div className="w-full flex-1">
              <label htmlFor="firstName" className="mb-1">
                Ime i prezime
              </label>
              <input
                id="firstName"
                name="first_name"
                placeholder="Ime i prezime"
                className={`pl-3 mb-4 rounded h-[38px] lg:h-[54px] mt-1 w-full border`}
                {...register('firstName', {
                  required: false,
                  maxLength: 50,
                  shouldDirty: true,
                })}
              />
            </div>
            <div className="flex flex-col flex-1 lg:flex-row">
              <div className="w-full flex-1">
                <label htmlFor="email" className="mb-1">
                  Email
                  <i className="m-1 text-invalid-red">*</i>
                </label>
                <input
                  id="email"
                  name="email"
                  placeholder="Email"
                  className={
                    !errors.email
                      ? 'pl-3 border-success-green border mb-4 rounded h-[38px] lg:h-[54px] mt-1 w-full'
                      : 'pl-3 border-invalid-red border mb-4 rounded h-[38px] lg:h-[54px] mt-1 w-full'
                  }
                  {...register('email', {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  })}
                  onKeyPress={(e) => {
                    if (e.code === 'Space' || e.keyCode === 32) {
                      e.preventDefault()
                    }
                  }}
                />
                {errors?.email && (
                  <p className="text-invalid-red -mt-4 mb-1 text-sm">
                    Email nije validan.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full text-base lg:text-xl">
            <label htmlFor="message" className="mb-1">
              Poruka
              <i className="text-invalid-red m-1">*</i>
            </label>
            <textarea
              {...register('message', {
                required: true,
              })}
              id="message"
              name="message"
              placeholder="Poruka"
              className={
                !errors.message
                  ? 'border-success-green border h-[148px] rounded pl-3 pt-2'
                  : 'border-invalid-red border h-[148px] rounded pl-3 pt-2'
              }
            ></textarea>
          </div>
          {errors?.message && (
            <p className="text-invalid-red">Poruka je obavezna.</p>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="bg-light-green text-white w-fit mt-8 px-8 py-4 rounded-3xl font-bold text-base lg:text-2xl lg:ml-auto"
          >
            {isLoading ? <div className="loader"></div> : 'Pošalji'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactUs
