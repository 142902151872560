import Link from 'react-scroll/modules/components/Link'
import facebook from 'assets/img/facebook.svg'
import instagram from 'assets/img/instagram.svg'
import linkedin from 'assets/img/linkedin.svg'
import facebookMobile from 'assets/img/facebook-mobile.svg'
import instagramMobile from 'assets/img/instagram-mobile.svg'
import linkedinMobile from 'assets/img/linkedin-mobile.svg'
import scaleup from 'assets/img/scaleup.svg'

const Footer = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  return (
    <div className="font-helvetica bg-dark-green w-screen flex justify-between lg:justify-center border-t border-white">
      <div className="max-w-[1110px] w-full flex flex-col px-6 lg:px-10 pt-[46px] lg:pt-[100px] pb-[32px] lg:pb-[60px] justify-between">
        <div className="flex justify-center items-center w-full gap-x-7 text-base lg:text-2xl text-white lg:text-light-green font-medium lg:border-[#D9DBE1] pb-8 lg:border-b lg:border-b-[0.5px]">
          <div className="flex flex-col justify-center gap-y-8 lg:flex-row gap-x-10 w-full lg:ml-20">
            <Link
              to="intro"
              smooth={true}
              offset={-50}
              className="cursor-pointer"
            >
              Funkcionalnosti
            </Link>
            <Link
              to="download"
              smooth={true}
              offset={-80}
              className="cursor-pointer"
            >
              Preuzmite aplikaciju
            </Link>
          </div>
          <div className="flex flex-col gap-y-8 lg:flex-row gap-x-10 w-full">
            <Link
              to="about"
              smooth={true}
              offset={-50}
              className="cursor-pointer"
            >
              O nama
            </Link>
            <Link
              to="contact"
              smooth={true}
              offset={-50}
              className="cursor-pointer"
            >
              Kontaktirajte nas
            </Link>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between text-[#D9DBE1] mt-8">
          <div className="flex lg:hidden mb-20">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/ScaleUPSarajevo/"
            >
              <img src={facebookMobile} alt="facebook"></img>
            </a>
            <a
              className="px-4"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/scaleup.ba/"
            >
              <img src={instagramMobile} alt="instagram"></img>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/scaleup-ba"
            >
              <img src={linkedinMobile} alt="linkedin"></img>
            </a>
          </div>
          <div className="flex items-center justify-center lg:justify-start flex-wrap">
            <p className="text-center lg:text-left mr-2 whitespace-nowrap">
              © {currentYear} Tefter, razvijeno od strane
            </p>
            <a target="_blank" rel="noreferrer" href="https://scaleup.ba/">
              <img src={scaleup} alt="scaleup" />
            </a>
            .
            <p className="text-center lg:text-left whitespace-nowrap ml-1">
              Sva prava zadržana.
            </p>
          </div>
          <div className="hidden lg:flex">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/ScaleUPSarajevo/"
            >
              <img src={facebook} alt="facebook"></img>
            </a>
            <a
              className="px-4"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/scaleup.ba/"
            >
              <img src={instagram} alt="instagram"></img>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/scaleup-ba"
            >
              <img src={linkedin} alt="linkedin"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
