import tefter from 'assets/img/realestates-mobile-screen.png'
import circleDots from 'assets/img/circle-dots.svg'
import magnifyingGlass from 'assets/img/magnifying-glass.svg'
import analytics from 'assets/img/analytics-icon.svg'
import saved from 'assets/img/saved-icon.svg'
import magnifyingGlassMobile from 'assets/img/glass-mobile.svg'
import analyticsMobile from 'assets/img/analytics-mobile.svg'
import savedMobile from 'assets/img/saved-mobile.svg'
import tefterScreen from 'assets/img/vehicles-mobile-screen.png'

const Intro = () => {
  return (
    <div
      id="intro"
      className="font-helvetica bg-faint-green h-auto min-h-[1500px] lg:min-h-[1360px] xl:min-h-[1024px] w-screen flex justify-center overflow-x-hidden"
    >
      <div className="flex flex-col lg:flex-row h-full w-full max-w-[1440px]">
        <div className="hidden lg:block w-1/3 relative lg:mt-[296px]">
          <img
            src={circleDots}
            alt="tefter"
            className="hidden lg:block lg:absolute top-72 -left-8 xl:-left-8"
          ></img>
          <img
            src={tefter}
            alt="tefter"
            className="hidden lg:block lg:absolute left-8 xl:left-20 h-[620px]"
          ></img>
        </div>
        <div className="w-full h-auto lg:w-2/3 flex flex-col mt-12 lg:mt-[132px] text-right px-6 lg:px-0 lg:mr-[80px] text-base lg:text-2xl">
          <p className="text-dark-green text-3xl lg:text-6xl font-black">
            Pojednostavljenje pretrage
          </p>
          <div className="flex justify-end mt-3">
            <p className="bg-light-green w-[160px] h-2"></p>
          </div>
          <p className="text-dark-green my-12 text-xl lg:text-2xl">
            Naša platforma je osmišljena da vam pruži najbolje alate i funkcije
            kako biste svoju pretragu automobila ili nekretnine učinili
            jednostavnom i efikasnom.
          </p>
          <div className="flex lg:max-w-[664px] lg:ml-auto bg-white lg:bg-transparent rounded p-4 lg:p-0 rounded rounded-xl border lg:border-transparent border-normal-gray">
            <div className="flex flex-col">
              <p className="flex items-center justify-end text-dark-green font-extrabold mb-2 text-xl lg:text-2xl">
                Napredne opcije pretrage
                <img
                  src={magnifyingGlassMobile}
                  alt="info"
                  className="ml-3 lg:hidden"
                ></img>
              </p>
              <p className="text-dark-black lg:text-medium-gray">
                Naš pretraživač vam omogućava da precizirate pretragu koristeći
                različite filtere, za automobile marku, model, godište,
                kilometražu, vrstu goriva i prijenos, za nekretnine
                pretraživanje mape, kvadratura, stanje ili godina izgradnje.
              </p>
            </div>
            <img
              src={magnifyingGlass}
              alt="info"
              className="hidden lg:block -mt-12"
            ></img>
          </div>
          <div className="flex my-12 lg:max-w-[664px] lg:ml-auto bg-white lg:bg-transparent p-4 lg:p-0 rounded rounded-xl border lg:border-transparent border-normal-gray">
            <div className="flex flex-col">
              <p className="flex items-center justify-end text-dark-green font-extrabold mb-2 text-xl lg:text-2xl">
                Analitika cijena
                <img
                  src={analyticsMobile}
                  alt="info"
                  className="ml-3 lg:hidden"
                ></img>
              </p>
              <p className="text-dark-black lg:text-medium-gray">
                Imajte uvid u prosječne cijene automobila ili nekretnina u vašem
                području ili kategoriji, kao i trendove cijena tokom vremena.
              </p>
            </div>
            <img
              src={analytics}
              alt="info"
              className="hidden lg:block -mt-12"
            ></img>
          </div>
          <div className="flex lg:max-w-[664px] lg:ml-auto bg-white lg:bg-transparent p-4 lg:p-0 rounded rounded-xl border lg:border-transparent border-normal-gray">
            <div className="flex flex-col">
              <p className="flex items-center justify-end text-dark-green font-extrabold mb-2 text-xl lg:text-2xl">
                Spašene pretrage
                <img
                  src={savedMobile}
                  alt="info"
                  className="ml-3 lg:hidden"
                ></img>
              </p>
              <p className="text-dark-black lg:text-medium-gray">
                Sačuvajte analitiku cijene za određeni automobil ili nekretninu
                sa unesenim filterima. Na taj način uvijek joj se možete vratiti
                bez potrebe za ponovnim pretraživanjem.
              </p>
            </div>
            <img
              src={saved}
              alt="info"
              className="hidden lg:block -mt-12"
            ></img>
          </div>
        </div>
        <div className="relative lg:hidden flex justify-end mt-[72px] mb-12">
          <img
            src={circleDots}
            alt="tefter"
            className="absolute -right-24 -top-8"
          ></img>
          <img src={tefterScreen} className="z-10 h-[450px]" alt="tefter"></img>
        </div>
      </div>
    </div>
  )
}

export default Intro
