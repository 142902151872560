import googlePlay from 'assets/img/google-play.svg'
import appStore from 'assets/img/app-store.svg'

const DownloadApp = () => {
  return (
    <div
      id="download"
      className="font-helvetica bg-dark-green w-screen flex flex-col justify-center items-center py-12 px-6 lg:py-[132px]"
    >
      <p className="text-3xl lg:text-6xl font-black text-white">
        Spremni da počnete<span className="text-light-green">?</span>
      </p>
      <div className="flex justify-end mt-3 lg:w-[564px] ml-32 lg:ml-0">
        <p className="bg-light-green w-[160px] h-2"></p>
      </div>
      <p className="text-center text-white text-xl lg:text-2xl my-12">
        Preuzmite našu aplikaciju i otključajte puni potencijal naše platforme.
      </p>
      <div className="flex gap-x-2 lg:gap-x-10">
        <a
          href="https://play.google.com/store/apps/details?id=com.internal_mobile"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer lg:w-auto h-14 lg:h-auto"
        >
          <img
            src={googlePlay}
            alt="google_play"
            className="animate-shadow"
          ></img>
        </a>
        <a
          href="https://apps.apple.com/us/app/tefter/id6455985979"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer lg:w-auto h-14 lg:h-auto"
        >
          <img src={appStore} alt="app_store" className="animate-shadow"></img>
        </a>
      </div>
    </div>
  )
}

export default DownloadApp
