import ellipse from 'assets/img/ellipse.svg'
import tefterScreen from 'assets/img/vehicles-mobile-screen.png'
import triangle from 'assets/img/bg-element-triangle.svg'
import blueEllipse from 'assets/img/bg-element-ellipse.svg'
import dots from 'assets/img/dots-bg.svg'
import blueDots from 'assets/img/blue-dots.svg'
import Link from 'react-scroll/modules/components/Link'

const Hero = () => {
  return (
    <div className="font-helvetica bg-dark-green min-h-screen pb-8 lg:pb-0 lg:h-[1024px] w-screen flex justify-center split-colors">
      <img
        src={ellipse}
        alt="bg"
        className="hidden xl:block absolute right-40"
      ></img>
      <div className="relative flex justify-between w-full max-w-[1440px] z-10 px-6 lg:px-0">
        <div className="flex flex-col w-full lg:w-2/3 text-white mt-[68px] lg:mt-[186px] lg:ml-24 max-w-[620px]">
          <h3 className="text-5xl lg:text-6xl font-black tracking-[0.8px] !leading-snug">
            Otkrijte <span className="text-sandy-yellow">pravu vrijednost</span>{' '}
            traženog automobila ili nekretnine
          </h3>
          <p className="mt-6 text-xl lg:text-3xl max-w-[620px]">
            Dobrodošli na Tefter! Upotrijebite našu platformu za analitiku
            cijena i pronađite najbolje ponude.
          </p>
          <Link
            to="download"
            smooth={true}
            offset={-80}
            className="cursor-pointer"
          >
            <button className="bg-light-green w-fit rounded-[32px] py-4 px-8 mt-8 font-extrabold text-base lg:text-2xl">
              Preuzmi aplikaciju
            </button>
          </Link>
        </div>
        <img
          src={dots}
          alt="bg"
          className="absolute bottom-[140px] right-0 lg:hidden"
        ></img>
        <div className="relative hidden lg:block w-1/3 mt-[120px]">
          <img src={tefterScreen} alt="bg" className="xl:ml-20 h-[620px]"></img>
          <img
            src={triangle}
            alt="bg"
            className="absolute -top-8  ml-32 -z-10 -left-20"
          ></img>
          <img
            src={blueEllipse}
            alt="bg"
            className="absolute top-24 ml-32 -z-10 right-0"
          ></img>
          <img
            src={blueDots}
            alt="bg"
            className="absolute bottom-62 ml-32 -z-10 right-0"
          ></img>
        </div>
      </div>
    </div>
  )
}

export default Hero
