import rocket from 'assets/img/rocket.svg'
import team from 'assets/img/team.svg'
import light from 'assets/img/light.svg'
import shadowOne from 'assets/img/shadow-one.svg'
import shadowTwo from 'assets/img/shadow-two.svg'
import shadowThree from 'assets/img/shadow-three.svg'

const AboutUs = () => {
  return (
    <div
      id="about"
      className="flex justify-center font-helvetica bg-light-green w-screen py-12 lg:pt-[132px] lg:pb-40 lg:px-12"
    >
      <div className="flex flex-col max-w-[1440px] px-6 lg:px-0">
        <div className="flex flex-col items-center">
          <p className="text-3xl lg:text-6xl text-white font-black">O nama</p>
          <div className="flex justify-center mt-3 lg:w-[564px] lg:ml-14">
            <p className="bg-white w-[160px] h-2"></p>
          </div>
          <p className="text-center text-white text-xl lg:text-2xl my-12">
            Naša misija: Olakšavanje procesa kupovine.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6">
          <div className="relative flex justify-center text-center bg-white rounded-2xl py-8 lg:py-[50px] px-4 lg:px-10 flex-1">
            <div className=" flex flex-col justify-center items-center">
              <p className="text-dark-green font-extrabold text-xl lg:text-3xl">
                Naša <span className="text-light-green">misija</span>
              </p>
              <p className="mt-4 lg:mt-6 text-dark-green text-base lg:text-2xl lg:opacity-60">
                Naša platforma je stvorena s ciljem da proces kupovine učini
                lakšim i ugodnijim za sve.
              </p>
              <img
                src={rocket}
                alt="mission"
                className="w-[64px] h-[64px] lg:w-[98px] lg:h-[98px] mt-8 lg:mt-20"
              ></img>
              <img
                src={shadowOne}
                alt="bg"
                className="absolute bottom-0 -left-0 right-0"
              ></img>
            </div>
          </div>
          <div className="relative flex justify-center text-center bg-white rounded-2xl py-8 lg:py-[50px] px-4 lg:px-10 flex-1">
            <div className="flex flex-col justify-center items-center">
              <p className="text-dark-green font-extrabold text-xl lg:text-3xl">
                Ko smo <span className="text-light-green">mi</span>
              </p>
              <p className="mt-4 lg:mt-6 text-dark-green text-base lg:text-2xl lg:opacity-60">
                Naš tim čini tim developera i dizajnera koji su posvećeni
                pružanju izuzetne usluge našim korisnicima.
              </p>
              <img
                src={team}
                alt="team"
                className="w-[64px] h-[64px] lg:w-[98px] lg:h-[98px] mt-8 lg:mt-20"
              ></img>
              <img
                src={shadowTwo}
                alt="bg"
                className="absolute bottom-0 -left-0 right-0"
              ></img>
            </div>
          </div>
          <div className="relative flex justify-center text-center bg-white rounded-2xl py-8 lg:py-[50px] px-4 lg:px-10 flex-1">
            <div className="flex flex-col justify-center items-center">
              <p className="text-dark-green font-extrabold text-xl lg:text-3xl">
                Sveobuhvatne{' '}
                <span className="text-light-green">informacije</span>
              </p>
              <p className="mt-4 lg:mt-6 text-dark-green text-base lg:text-2xl lg:opacity-60">
                Cijenimo transparentnost i iskrenost, zbog čega pružamo
                sveobuhvatne informacije o svakom produktu na našoj platformi.
              </p>
              <img
                src={light}
                alt="light"
                className="w-[64px] h-[64px] lg:w-[98px] lg:h-[98px] mt-8 lg:mt-20"
              ></img>
              <img
                src={shadowThree}
                alt="bg"
                className="absolute bottom-0 -left-0 right-0"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
