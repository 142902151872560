import {useState} from 'react'
import Link from 'react-scroll/modules/components/Link'
import logo from 'assets/img/tefter.svg'
import logoWhite from 'assets/img/tefter-white.svg'
import menu from 'assets/img/menu.svg'
import googlePlay from 'assets/img/google-play.svg'
import appStore from 'assets/img/app-store.svg'

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="font-helvetica bg-dark-green lg:bg-white w-screen flex justify-between lg:justify-center shadow-sm sticky top-0 z-20">
      <div className="hidden max-w-[1440px] w-full lg:flex px-10 py-6 justify-between">
        <img src={logo} alt="tefer"></img>
        <div className="flex gap-x-7 text-2xl text-light-green font-medium">
          <Link to="intro" smooth={true} offset={0} className="cursor-pointer">
            Funkcionalnosti
          </Link>
          <Link to="about" smooth={true} offset={0} className="cursor-pointer">
            O nama
          </Link>
          <Link
            to="contact"
            smooth={true}
            offset={0}
            className="cursor-pointer"
          >
            Kontakt
          </Link>
        </div>
        <div></div>
      </div>
      <div className="flex lg:hidden p-6 justify-between w-full">
        <img src={logoWhite} alt="tefer"></img>
      </div>

      <img
        src={menu}
        alt="menu"
        className="mr-4 cursor-pointer lg:hidden"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      ></img>
      <div
        className={
          mobileMenuOpen
            ? 'flex flex-col justify-start items-start w-full h-full fixed overflow-y-scroll top-0 left-0 p-6 flex z-20 bg-dark-green text-white'
            : 'hidden'
        }
      >
        <div className="flex w-full justify-between">
          <Link to="/">
            <img
              src={logoWhite}
              alt="logo"
              className="mr-auto"
              onClick={() => setMobileMenuOpen(false)}
            ></img>
          </Link>
          <button
            className="xl:hidden visible flex top-0 right-0 z-20 relative w-10 h-10 text-white focus:outline-none"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <span
                className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                  mobileMenuOpen ? 'rotate-45 delay-200' : '-translate-y-1.5'
                }`}
              ></span>
              <span
                className={`absolute h-0.5 bg-white transform transition-all duration-200 ease-in-out ${
                  mobileMenuOpen
                    ? 'w-0 opacity-50'
                    : 'w-5 delay-200 opacity-100'
                }`}
              ></span>
              <span
                className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                  mobileMenuOpen ? '-rotate-45 delay-200' : 'translate-y-1.5'
                }`}
              ></span>
            </div>
          </button>
        </div>
        <div className="flex flex-col text-3xl mt-16">
          <Link
            to="intro"
            smooth={true}
            offset={-80}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="flex flex-col"
          >
            {' '}
            <div className="flex items-start cursor-pointer">
              <p className="mb-8">Funkcionalnosti</p>
            </div>
          </Link>

          <Link
            to="about"
            smooth={true}
            offset={-80}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="mb-8"
          >
            O Nama
          </Link>

          <Link
            to="contact"
            smooth={true}
            offset={-80}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="mb-8 text-sandy-yellow"
          >
            Kontakt
          </Link>
        </div>
        <div className="flex justify-between gap-x-2 mt-auto mb-12 mx-auto">
          <a
            href="https://play.google.com/store/apps/details?id=com.internal_mobile"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer lg:w-auto h-14 lg:h-auto"
          >
            <img
              src={googlePlay}
              alt="google_play"
              className="animate-shadow"
            ></img>
          </a>
          <a
            href="https://apps.apple.com/us/app/tefter/id6455985979"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer lg:w-auto h-14 lg:h-auto"
          >
            <img
              src={appStore}
              alt="app_store"
              className="animate-shadow"
            ></img>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Navbar
